<template>
  <footer class="bg-gray-100 py-2 px-24">
    <div>
      <div class="text-center">
        <h2 class="my-6 font-thin">Vuoi saperne di più?</h2>
        <div class="grid grid-cols-3 align-center justify-center gap-x-2 justify-items-center social-contacts text-sm text-white">
          <a
            href="mailto:info@lyber.it"
            aria-label="email lyber"
            class="bg-mnet p-2 w-24"
            onclick="ga('send', 'event', 'link', 'contatti', 'Email');"
          >
            <div class="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#f4f4f4"
                  d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"
                />
              </svg>
              <p>info@lyber.it</p>
            </div>
          </a>
          <a
            href="https://www.facebook.com/Lyber.it/"
            class="social-facebook p-2 w-24"
          >
            <div class="flex flex-col items-center">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="facebook-square"
                role="img"
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                ></path>
              </svg>

              <p>Facebook</p>
            </div>
          </a>
          <a
            href="https://twitter.com/lyber_it"
            aria-label="account twitter"
            class="social-x p-2 w-24"
            onclick="ga('send', 'event', 'link', 'contatti', 'Twitter');"
          >
            <div class="flex flex-col items-center">
              <svg width="26" height="22" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 462.799">
                <path fill="#fff" fill-rule="nonzero" d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"/>
              </svg>
              <p>X Twitter</p>
            </div>
          </a>
        </div>
        <div class="my-3.5 text-sm font-sans">
          <p>Tutti i prezzi sono IVA esclusa</p>
        </div>
        <p aria-label="link per dettagli"
            class="flex flex-col text-sm text-mnet font-sans md:block md:divide-x md:divide-gray-700"
        >
          <a
            href="https://helpcenter.messagenet.com/hc/it/articles/360015914253"
            rel="noopener"
            target="_blank"
            class="px-2 py-4 md:py-0 hover:underline"
            >Dettagli dell'offerta</a
          >
          <a
            class="px-2 py-4 md:py-0 hover:underline cursor-pointer"
            rel="noopener"
            target="_blank"
            href="https://www.messagenet.com/it/legal/contratto.html"
            >Contratto</a
          >
          <a
            class="px-2 py-4 md:py-0 hover:underline"
            rel="noopener"
            target="_blank"
            href="https://www.messagenet.com/it/legal/informativa.html"
            >Privacy</a
          >
          <a
            class="px-2 py-4 md:py-0 hover:underline cursor-pointer"
            rel="noopener"
            target="_blank"
            href="https://www.messagenet.com/it/legal/informativa_cookie.html"
            >Informativa Cookie</a
          >
          <a
            class="px-2 py-4 md:py-0 hover:underline cursor-pointer iubenda-cs-preferences-link"
            rel="noopener"
            href="#"
            >Modifica preferenze Cookie</a
          >
          <a
            target="_blank"
            rel="noopener"
            href="https://messagenet.com/it/cartaservizi/"
            class="px-2 py-4 md:py-0 hover:underline cursor-pointer"
            >Carta dei servizi e Trasparenza tariffaria</a
          >
          <a
            target="_blank"
            rel="noopener"
            class="px-2 py-4 md:py-0 hover:underline"
            href="https://messagenet.com/it/legal/conciliaweb.html"
            >Risoluzione delle controversie</a
          >
        </p>
      </div>
      <hr class="my-6">
      <p class="text-xs text-center font-sans">
        <strong>Lyber</strong> è un servizio offerto da
        <span
          itemprop="copyrightHolder"
          itemtype="http://schema.org/Organization"
          ><span itemprop="name"
            ><a
              href="http://www.messagenet.com/"
              class="text-mnet"
              data-event="Sito Corporate"
              >Messagenet S.p.A.</a
            ></span
          >
          -
          <span
            itemprop="address"
            itemtype="http://schema.org/PostalAddress"
            ><span itemprop="streetAddress">via Lanzone 4</span>,
            <span itemprop="postalCode">20123</span>
            <span itemprop="addressLocality">Milano (MI)</span> </span
          >, P.IVA/C.F. <span itemprop="taxID">13004930155</span>, REA MI
          1605496, cap.soc. Euro 200.000 i.v.</span
          ><br />© <span itemprop="copyrightYear">2024</span> Messagenet
        </p>
      </div>
    </footer>
  </template>
  
  <style scoped>
  .social-contacts {
    grid-template-columns: auto auto auto;
  }
  
  .social-facebook {
    background-color: #5f9ae4;
  }
  
  .social-x {
    background-color: #333333;
  }
  </style>