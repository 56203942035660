export const useUrls = () => {
  const config = useRuntimeConfig().public
    
  const getLyberLoginUrl = () => {
    const redirectUri = new URL('dashboard', config.NUXT_APP_MY_LYBER_URL)
    redirectUri.searchParams.set('provider', 'messagenet')
    
    const searchParams = new URLSearchParams()
    searchParams.set('client_id', 2)
    searchParams.set('is_lyber', 1)
    searchParams.set('redirect_uri', redirectUri.toString())
    searchParams.set('response_type', 'code')
    searchParams.set('scope', 'login')
    const redirectAfterLoginUrl = `/oauth/authorize?${searchParams.toString()}`
    
    const url = new URL('login', config.NUXT_APP_OAUTH_URL)
    url.searchParams.set('product_name', 'lyber')
    url.searchParams.set('redirect_after_login', redirectAfterLoginUrl)
  
    return url.toString()
  }

  const lyberProvisioningUrl = `${config.NUXT_APP_PROVISIONING_URL}/lyber`
  const lyberLoginUrl = getLyberLoginUrl()
  const lyberSiteUrl = config.NUXT_APP_LYBER_SITE_URL

  return { lyberSiteUrl, lyberLoginUrl, lyberProvisioningUrl }
}