<template>
  <header id="menu"
    class="z-50 left-0 flex justify-start items-center text-lg w-full h-20 font-normal px-vw6 text mb-8 lg:mb-0"
    :class="isArticle ? 'fixed bg-white shadow-lg' : 'relative'">
    <a :href="lyberSiteUrl" class="cursor-pointer items-center">
      <span class="sr-only">Lyber</span>
      <img alt="Lyber Logo" src="~/assets/images/logo.png" width="100" >
    </a>
    <button 
      class="inline-block w-8 h-8 relative lg:hidden bg-transparent text-gray-600 ml-auto"
      @click="menuOpen = !menuOpen"
      aria-label="open menu">
       <span class="block absolute h-0.5 w-7 bg-current transform transition duration-500 ease-in-out"
        :class="{'rotate-45': menuOpen,' -translate-y-1.5': !menuOpen }"></span>
       <span class="block absolute h-0.5 w-7 bg-current transform transition duration-500 ease-in-out"
        :class="{'opacity-0': menuOpen } "></span>
       <span class="block absolute h-0.5 w-7 bg-current transform transition duration-500 ease-in-out"
        :class="{'-rotate-45': menuOpen, ' translate-y-1.5': !menuOpen}"></span>
    </button>

    <div class="bg-transparent fixed z-10 inset-0 cursor-pointer"
      :class="{ 'block' : menuOpen , 'hidden' : !menuOpen}" @click="menuOpen = false" />
    <nav class="absolute lg:ml-3 lg:relative top-16 right-0 lg:top-0 z-20 lg:flex flex-col lg:flex-row lg:justify-between w-full p-6 lg:p-0
    bg-white lg:bg-transparent shadow-lg lg:shadow-none"
      :class="{ 'flex' : menuOpen , 'hidden' : !menuOpen}">
      <div class="flex items-start lg:items-center justify-start flex-col lg:flex-row">
        <a class="px-2 mb-2 lg:mb-0" :href="`${lyberSiteUrl}/?#how`" rel="noopener">COME FUNZIONA</a>
        <a class="px-2 mb-2 lg:mb-0" :href="`${lyberSiteUrl}/?#features`" rel="noopener">CARATTERISTICHE</a>
        <a class="px-2 mb-2 lg:mb-0" :href="`${lyberSiteUrl}/?#prices`" rel="noopener">QUANTO COSTA</a>
        <a class="px-2 mb-2 lg:mb-0" :href="`${lyberSiteUrl}/?#examples`" rel="noopener">ESEMPI</a>
        <a class="px-2 mb-2 lg:mb-0 text-mnet" :href="`/blog/`" rel="noopener">BLOG</a>
      </div>
      <div class="flex items-start lg:items-center justify-start flex-col lg:flex-row">
        <a id="login"
          :href="lyberLoginUrl"
          rel="noopener"
          target="_blank"
          class="cursor-pointer py-2 px-3 border border-solid border-transparent font-semibold
            hover:border-mnet rounded-3xl mr-2 text-mnet mb-2 lg:mb-0">
          ACCEDI
        </a>
        <a
          :href="lyberProvisioningUrl"
          target="_blank"
          rel="noopener"
          class="text-white max-h-full text-center border border-solid border-mnet font-semibold
            hover:bg-white hover:text-mnet rounded-3xl bg-mnet py-2 px-3 mb-2 lg:mb-0">
          PROVALO GRATIS
        </a>
      </div>
      <div class="lg:hidden mt-3">
        <hr>
        <TagArea />
      </div>
    </nav>
  </header>
</template>

<script setup>
import TagArea from '@/components/TagArea'
const { lyberSiteUrl, lyberLoginUrl, lyberProvisioningUrl } = useUrls()

defineProps({
  isArticle: {
    type: Boolean,
    default: false
  }
})
const menuOpen = ref(false)

</script>

<style  scoped>
  .text {
    font-family: "Source Sans Pro"
  }
</style>